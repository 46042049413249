<template>
  <CContainer class="d-flex align-items-center center">
    <CRow class="w-100 justify-content-center m-0">
      <CCol md="12">
        <div class="w-100">
          <div class="text-center">
            <img src="/img/brand/logo_skl.svg" alt="logo" class="w-300">
            <h2 class="font-weight-bold mt-3 text-wel">Welcome to SKL Admin Website!<br>Please select menu.</h2>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: "Welcome",
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  background: white;
}

.w-300 {
  width: 250px;
}
</style>